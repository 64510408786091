import React from "react"
import Container from "@mui/material/Container"

const CenterColumn = ({ children, ...other }) => (
  <Container maxWidth="md" {...other}>
    {children}
  </Container>
)

export default CenterColumn

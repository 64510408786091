import React, { useState } from "react"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Drawer from "@mui/material/Drawer"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { Link as GatsbyLink } from "gatsby"
import { Typography } from "@mui/material"
import CenterColumn from "../CenterColumn"
import Search from "../Search"
import { styled } from "@mui/system"

const SiteTitleLink = styled(GatsbyLink)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: "none",
}))

const MenuLink = styled(GatsbyLink)(({ theme }) => ({
  color: theme.palette.primary.contrast,
}))

const Appbar = ({ siteTitle }) => {
  const [open, setOpen] = useState()
  const toggleOpen = () => setOpen(prevOpenValue => !prevOpenValue)

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "primary.lightShade" }}>
        <CenterColumn>
          <Toolbar disableGutters>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open menu"
              onClick={toggleOpen}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" noWrap component="div" sx={{ mr: 2 }}>
              <SiteTitleLink to="/">{siteTitle}</SiteTitleLink>
            </Typography>
            <Search />
          </Toolbar>
        </CenterColumn>
      </AppBar>
      <Drawer open={open} onClose={toggleOpen}>
        <List>
          <ListItem>
            <ListItemText primary={<MenuLink to="/">Home</MenuLink>} />
          </ListItem>
          <ListItem>
            <ListItemText primary={<MenuLink to="/builds">Builds</MenuLink>} />
          </ListItem>
          <ListItem>
            <ListItemText primary={<MenuLink to="/blog">Blog</MenuLink>} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <MenuLink to="/piecetypes-and-storage">
                  Lego organization guide
                </MenuLink>
              }
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}

export default Appbar

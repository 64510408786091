import { createTheme } from "@mui/material/styles"
import "@fontsource/lora"

const theme = createTheme({
  palette: {
    primary: {
      main: "#FBB13C", // Yellow
      shade: "#e08610", // Shade yellow
      lightShade: "#ffe2b5",
      contrast: "#B66D0D", // Dark yellow
    },
    secondary: {
      main: "#0A2538", // Dark blue
      contrast: "#57B8FF", // Light blue
    },
  },
  typography: {
    content: {
      fontFamily: "Lora",
      fontSize: "1.1rem",
    },
    h2: {
      fontSize: "3rem",
      fontWeight: "bold",
      marginTop: ".5em",
      marginBottom: ".5em",
      textAlign: "center",
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginTop: ".5em",
      marginBottom: ".5em",
      textAlign: "center",
    },
  },
})

export default theme

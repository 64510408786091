import React, { useEffect, useState } from "react"
import { Index } from "elasticlunr"
import Downshift from "downshift"
import TextField from "@mui/material/TextField"
import Paper from "@mui/material/Paper"
import MenuItem from "@mui/material/MenuItem"
import { navigate } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const Search = () => {
  const { siteSearchIndex } = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `)

  const [results, setResults] = useState([])
  const [index, setIndex] = useState(null)

  useEffect(() => {
    const loadedIndex = Index.load(siteSearchIndex.index)
    setIndex(loadedIndex)
  }, [siteSearchIndex])

  const handleSelection = item => {
    navigate(`/${item.path.toLowerCase()}`)
  }

  const itemToString = item => (item ? item.title : "")

  const search = evt => {
    const query = evt.target.value
    setResults(
      index
        .search(query, {})
        // Map over each ID and return the full document
        .map(({ ref }) => index.documentStore.getDoc(ref))
    )
  }

  return (
    <Downshift onChange={handleSelection} itemToString={itemToString}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
      }) => (
        <div>
          <TextField
            {...getInputProps({
              onChange: search,
            })}
            size="small"
            variant="outlined"
            label="Search"
            color="secondary"
          />

          {isOpen ? (
            <Paper
              square
              {...getMenuProps()}
              sx={{ position: "absolute", mt: 1, zIndex: 1 }}
            >
              {results.map((page, index) => (
                <MenuItem
                  key={page.id}
                  component="div"
                  {...getItemProps({ item: page })}
                  selected={highlightedIndex === index}
                >
                  <strong>{page.type}</strong>: {page.title}
                </MenuItem>
              ))}
            </Paper>
          ) : null}
        </div>
      )}
    </Downshift>
  )
}

export default Search

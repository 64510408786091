/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AppBar from "./AppBar"
import BreadcrumbsContainer from "./BreadcrumbsContainer"
import CssBaseline from "@mui/material/CssBaseline"
import Container from "@mui/material/Container"
import "typeface-roboto"
import Divider from "@mui/material/Divider"
import Helmet from "react-helmet"
import theme from "../muiTheme"
import { ThemeProvider } from "@mui/material/styles"
import CenterColumn from "./CenterColumn"

const Layout = ({ children, breadcrumbs }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <CssBaseline />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <ThemeProvider theme={theme}>
        <AppBar siteTitle={site.siteMetadata.title} />
        <ThemedContainer breadcrumbs={breadcrumbs}>{children}</ThemedContainer>
      </ThemeProvider>
    </>
  )
}

const ThemedContainer = ({ children, breadcrumbs }) => {
  return (
    <>
      <main>
        {breadcrumbs && (
          <CenterColumn sx={{ mb: 4 }}>
            <BreadcrumbsContainer items={breadcrumbs} />
            <Divider variant="middle" />
          </CenterColumn>
        )}
        {children}
      </main>
      <Divider variant="middle" sx={{ my: 5 }} />
      <Container maxWidth="md">
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </Container>
    </>
  )
}

export default Layout

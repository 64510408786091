import React from "react"
import { Link as RouterLink } from "gatsby"
import Link from "@mui/material/Link"
import HomeIcon from "@mui/icons-material/Home"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Box } from "@mui/material"

const BreadcrumbsContainer = ({ items }) => {
  const BreadcrumbLink = ({ url, title, Icon }) => (
    <Link color="inherit" component={RouterLink} to={url} key={url}>
      {Icon ? Icon : title}
    </Link>
  )

  return (
    <Box sx={{ py: 2 }}>
      <Breadcrumbs aria-label="Breadcrumb">
        <BreadcrumbLink url="/" title="Home" Icon={<HomeIcon />} />
        {items.map(breadcrumb => {
          return <BreadcrumbLink {...breadcrumb} key={breadcrumb.url} />
        })}
      </Breadcrumbs>
    </Box>
  )
}

export default BreadcrumbsContainer
